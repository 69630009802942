import APILayer from './APILayer';
import { WindowForm, WindowSimple } from '../components/Admin/Windows/windowsLib';
export default class WindowsAPI {
    APILayer = new APILayer();

    async getAllWindows(): Promise<WindowSimple[]> {
        return this.APILayer.get('/api/windows/GetAll').then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to retrieve windows.');
            }
            return response.json();
        });
    }

    async createWindow(
        newWindow: Pick<
            WindowForm,
            'windowStart' | 'windowEnd' | 'organizations' | 'dataCollectedEnd' | 'dataCollectedStart' | 'name'
        >,
    ): Promise<WindowSimple> {
        const body = {
            Name: newWindow.name,
            WindowStart: newWindow.windowStart,
            WindowEnd: newWindow.windowEnd,
            Organizations: newWindow.organizations,
            DataCollectedStart: newWindow.dataCollectedStart,
            DataCollectedEnd: newWindow.dataCollectedEnd,
        };

        // JS date comparison works (but not equality) - need to change to moment.js for sanity
        if (new Date(newWindow.dataCollectedStart) > new Date(newWindow.dataCollectedEnd)) {
            throw new Error('Data collection start date must occur before the data collection end date.');
        }

        return this.APILayer.post('/api/windows/Add', body)
            .then((response) => {
                if (response.status !== 200) {
                    return response.json();
                }
                return true;
            })
            .then((data) => {
                if (data === true) {
                    return data;
                }
                throw data;
            });
    }

    async createWindowOrg(editWindow: Pick<WindowForm, 'windowId' | 'organizations'>) {
        const body = {
            WindowId: editWindow.windowId,
            Organizations: editWindow.organizations,
        };

        return this.APILayer.post('/api/windows/AddOrganizations', body).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to create organization in window.');
            }

            return response;
        });
    }

    async deleteWindow(WindowId: NonNullable<WindowSimple>['windowId']) {
        const body = {
            WindowId,
        };

        return this.APILayer.post('/api/windows/Delete', body).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to delete window.');
            }
        });
    }

    async deleteWindowOrg(editWindow: Pick<WindowSimple, 'windowId' | 'organizations'>) {
        const body = {
            WindowId: editWindow.windowId,
            Organizations: editWindow.organizations,
        };

        return this.APILayer.post('/api/windows/DeleteOrganizations', body).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to delete organization.');
            }
        });
    }

    async updateWindow(editWindow: WindowForm): Promise<WindowSimple> {
        const body = {
            Name: editWindow.name,
            WindowId: editWindow.windowId,
            WindowStart: editWindow.windowStart,
            WindowEnd: editWindow.windowEnd,
            Organizations: editWindow.organizations,
            DataCollectedStart: editWindow.dataCollectedStart,
            DataCollectedEnd: editWindow.dataCollectedEnd,
        };

        return this.APILayer.post('/api/windows/Edit', body).then((response) => {
            let data: any = response.json();
            if (response.status !== 200) {
                if (data[0] !== undefined) {
                    if (data[0].organizationId !== undefined) {
                        throw data;
                    }
                } else {
                    throw new Error('Unable to edit window.');
                }
            }

            return data;
        });
    }

    async archiveWindow(WindowId: NonNullable<WindowSimple>['windowId']) {
        const body = { WindowId };

        return this.APILayer.post('/api/windows/Archive', body).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to deactivate window.');
            }
        });
    }

    async emailWindowOrganizations(window: WindowForm, emailBody: string) {
        const body = {
            Window: window,
            EmailBody: emailBody,
        };

        return this.APILayer.post('/api/windows/EmailOrganizations', body).then((response) => {
            if (response.status !== 200) {
                throw new Error('Unable to delete organizations from window.');
            }

            return response.json();
        });
    }

    async sendNotification(windowId: number) {
        return this.APILayer.post(`/api/windows/EmailWindowSubmissionsSummary/${windowId}`, {}).then(
            async (response) => {
                if (response.status === 400) {
                    const result = await response.text();
                    throw new Error(result);
                }

                if (response.status !== 200) {
                    throw new Error('Unable to send notification');
                }

                return;
            },
        );
    }
}
