import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { WindowSimple } from '../Admin/Windows/windowsLib';
import formatDate from '../../lib/formatDate';
import TooltipPreset from '../common/TooltipPreset';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    children: React.ReactChild;
    currentTimeframe: WindowSimple;
}
export default function InformedConsentWrapper({ children, currentTimeframe }: IProps) {
    return (
        <Container>
            <Row className="d-flex justify-content-center mt-4 mb-4">
                <Col xs={12}>
                    <p className="font-weight-bolder h6">
                        Timeframe Details{' '}
                        <TooltipPreset text="Upload new consents for clients served in this period only">
                            <FontAwesomeIcon className="mt-1 ml-2 file-upload-tooltip" icon={faQuestionCircle} />
                        </TooltipPreset>
                    </p>
                    <p className="text-muted mb-1">{currentTimeframe.name}</p>
                    <p className="text-muted mb-1">
                        {formatDate(currentTimeframe.dataCollectedStart)} -{' '}
                        {formatDate(currentTimeframe.dataCollectedEnd)}
                    </p>
                </Col>
            </Row>
            {children}
            <p className="mt-4 text-center text-nowrap">
                Your files will be uploaded directly to DWD’s storage account.
            </p>
        </Container>
    );
}
