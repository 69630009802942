import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import formatDate from '../../lib/formatDate';
import { WindowSimple } from '../Admin/Windows/windowsLib';
import TooltipPreset from '../common/TooltipPreset';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    window: WindowSimple;
    children: React.ReactChild;
}
export default function SectionFileSelectWrapper({
    window: { name, dataCollectedStart, dataCollectedEnd },
    children,
}: IProps) {
    return (
        <Container>
            <Row className="d-flex justify-content-start mt-4 mb-4">
                <Col xs={12}>
                    <p className="font-weight-bolder h6">
                        Timeframe Details{' '}
                        <TooltipPreset text="Upload data for clients served in this period">
                            <FontAwesomeIcon className="mt-1 ml-2 file-upload-tooltip" icon={faQuestionCircle} />
                        </TooltipPreset>
                    </p>
                    <p className="text-muted mb-1">{name}</p>
                    <p className="text-muted mb-1">
                        {formatDate(dataCollectedStart)} - {formatDate(dataCollectedEnd)}
                    </p>
                </Col>
            </Row>
            {children}
            <p className="mt-4 text-center text-nowrap">
                Your files will be checked for viruses, secured, and hashed. No data is currently being transmitted.
            </p>
        </Container>
    );
}
