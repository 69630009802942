import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { SubmissionType } from '../../api/SubmissionsAPI';
import AppContext from '../../AppContext';
import formatDate from '../../lib/formatDate';
import { WindowSimple } from '../Admin/Windows/windowsLib';
import TooltipPreset from '../common/TooltipPreset';

interface Timeframes {
    nextTimeframe: WindowSimple;
    currentTimeframes: WindowSimple[];
}
interface IProps extends Timeframes {
    setTimeframes(timeframes: Timeframes): void;
    setCurrentTimeframe: (timeframe: WindowSimple) => void;
    openModal(modal: SubmissionType): void;
}
export default function UploadHeader({
    currentTimeframes,
    nextTimeframe,
    openModal,
    setTimeframes,
    setCurrentTimeframe,
}: IProps) {
    const { user } = useContext(AppContext);
    const lastUser = useRef<typeof user>();
    useEffect(() => {
        if (user !== lastUser.current) {
            lastUser.current = { ...user };
            let windows = user.userData!.organizationSimple?.windows;
            if (windows?.length) {
                let currentDate = new Date();
                let timeframes = {} as Timeframes;

                let _currentTimeframes = [] as WindowSimple[];

                windows.forEach((window) => {
                    let windowStart = new Date(window.windowStart!);
                    let windowEnd = new Date(window.windowEnd!);

                    if (
                        windowStart.getTime() <= currentDate.getTime() &&
                        windowEnd.getTime() >= currentDate.getTime()
                    ) {
                        _currentTimeframes.push(window);
                    }
                });

                timeframes.currentTimeframes = _currentTimeframes;

                windows.sort((a, b) => {
                    let aVal = new Date(a.windowStart!);
                    let bVal = new Date(b.windowStart!);

                    if (aVal.getTime() < bVal.getTime()) {
                        return -1;
                    }
                    if (aVal.getTime() > bVal.getTime()) {
                        return 1;
                    }
                    return 0;
                });

                for (let i = 0; i < windows.length; i++) {
                    let windowStart = new Date(windows[i].windowStart!);
                    if (currentDate.getTime() < windowStart.getTime()) {
                        timeframes.nextTimeframe = windows[i];
                        break;
                    }
                }
                if (Object.keys(timeframes).length) {
                    setTimeframes(timeframes);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <Container fluid className="bg-light text-center pt-4 pb-4">
            <Row className="my-4 justify-content-center">
                <Col sm="8" md="6">
                    <h6>
                        All CBOs must have their MPH Report, Signed Informed Consent Forms, and Informed Consent List
                        successfully submitted by 5:00pm ET on the closing date of the Hashing Timeframe. Failure to
                        upload files will result in all CBOs being required to resubmit all files.
                    </h6>
                </Col>
            </Row>

            <Container>
                <Row>
                    {currentTimeframes?.map((timeframe, index) => {
                        if (!timeframe.windowId) return null;

                        return (
                            <Col key={timeframe.windowId} lg="12" xl="6" className="py-2">
                                <Card>
                                    <Card.Body>
                                        <Card.Title className="text-left">
                                            {timeframe?.name ?? 'Timeframe ' + index}
                                        </Card.Title>
                                        <Card.Subtitle className="text-left mb-4 text-muted">
                                            <TimeframeCountdown
                                                {...{
                                                    currentTimeframe: timeframe,
                                                    nextTimeframe,
                                                }}
                                            />
                                        </Card.Subtitle>

                                        <Row>
                                            <Col xs={5}>
                                                <p className="font-weight-bold d-flex align-content-center justify-content-start">
                                                    Data Period
                                                </p>
                                            </Col>
                                            <Col>
                                                <p className="font-weight-normal d-flex align-content-center justify-content-end">
                                                    {formatDate(timeframe.dataCollectedStart)} -{' '}
                                                    {formatDate(timeframe.dataCollectedEnd)}
                                                    <TooltipPreset text="The Data Period covers the time that clients were served.">
                                                        <FontAwesomeIcon className="ml-1 h6" icon={faQuestionCircle} />
                                                    </TooltipPreset>
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5}>
                                                <p className="font-weight-bold d-flex align-content-center justify-content-start">
                                                    Hashing Timeframe
                                                </p>
                                            </Col>
                                            <Col>
                                                <p className="font-weight-normal d-flex align-content-center justify-content-end">
                                                    {formatDate(timeframe.windowStart)} -{' '}
                                                    {formatDate(timeframe.windowEnd)}
                                                    <TooltipPreset text="The Hashing Timeframe is when you can submit files for hashing and the signed informed consent forms and list.">
                                                        <FontAwesomeIcon className="ml-1 h6" icon={faQuestionCircle} />
                                                    </TooltipPreset>
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row className="py-3">
                                            <Col sm="12" className="mb-2">
                                                <Button
                                                    variant="outline-primary"
                                                    className="w-100"
                                                    onClick={() => {
                                                        setCurrentTimeframe(timeframe);
                                                        openModal(SubmissionType.MPH_REPORT);
                                                    }}
                                                >
                                                    Upload MPH Report for Hashing
                                                </Button>
                                            </Col>
                                            <Col sm="12">
                                                <Button
                                                    variant="outline-primary"
                                                    className="w-100"
                                                    onClick={() => {
                                                        setCurrentTimeframe(timeframe);
                                                        openModal(SubmissionType.INFORMED_CONSENT);
                                                    }}
                                                >
                                                    Upload Informed Consent List and Signed Forms
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </Container>
    );
}

function TimeframeCountdown({
    currentTimeframe,
    nextTimeframe,
}: {
    currentTimeframe: WindowSimple;
    nextTimeframe: WindowSimple;
}) {
    const currentWindowCountdown = useCallback(() => {
        let todaysDate = new Date();
        let endDate = new Date(currentTimeframe.windowEnd!);
        let diffInTime = endDate.getTime() - todaysDate.getTime();
        let calculateDiff = Math.floor(diffInTime / (1000 * 60 * 60 * 24));
        return calculateDiff;
    }, [currentTimeframe.windowEnd]);

    const futureWindowCountdown = useCallback(() => {
        const todaysDate = new Date();
        const endDate = new Date(nextTimeframe.windowStart!);
        const diffInTime = endDate.getTime() - todaysDate.getTime();
        return Math.floor(diffInTime / (1000 * 60 * 60 * 24));
    }, [nextTimeframe.windowStart]);

    let todaysDate = new Date();
    let currentEndDate = new Date(currentTimeframe.windowEnd!);
    let currentStartDate = new Date(currentTimeframe.windowStart!);
    let futureStartDate = new Date(nextTimeframe.windowStart!);

    let timeTilEnd = +currentEndDate - +todaysDate;
    let minTilEnd = Math.round(((timeTilEnd % 86400000) % 3600000) / 60000);

    let daysTilEnd = currentWindowCountdown();

    if (
        todaysDate.getTime() > currentStartDate.getTime() &&
        todaysDate.getTime() < currentEndDate.getTime() &&
        minTilEnd > 0
    ) {
        if (daysTilEnd < 1) {
            return <>The timeframe ends on {formatDate(currentEndDate)} at 5:00PM</>;
        } else {
            let pluralized = daysTilEnd === 1 ? 'is 1 day' : `are ${daysTilEnd} days`;
            return <>There {pluralized} left in the current Timeframe</>;
        }
    } else if (!currentTimeframe.windowEnd && todaysDate.getTime() < futureStartDate.getTime()) {
        return <>Your timeframe opens in {futureWindowCountdown()} day(s)</>;
    } else {
        return <>There are no open timeframes</>;
    }
}
