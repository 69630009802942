import { Container, Row, Col, Form, Table } from 'react-bootstrap';
import React, { useState, useMemo, ChangeEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { SubmissionType, Submission } from '../../../api/SubmissionsAPI';
import { WindowSimple, formatDate } from '../Windows/windowsLib';
import Loading from '../../common/Loading';
import TooltipPreset from '../../common/TooltipPreset';
import { SortIcon, sort } from '../../User/SortIcon';
import { OrganizationSimple } from '../../../api/OrganizationsAPI';

import './SubmissionsTable.scss';

enum SortKeys {
    USER = 'USER',
    ORGANIZATION = 'ORGANIZATION',
    CREATED = 'CREATED',
    TIMEFRAME = 'TIMEFRAME',
    REPORT_TYPE = 'REPORT_TYPE',
    UPLOAD_SUCCEEDED = 'UPLOAD_SUCCEEDED',
}

const submissionLabels = {
    [SubmissionType.UNSET]: '—',
    [SubmissionType.MPH_REPORT]: 'MPH Report',
    [SubmissionType.INFORMED_CONSENT]: 'Informed Consent',
};

interface IFilters {
    year: number;
    user: string;
    timeframe: number;
    reportType: SubmissionType;
}
const defaultFilters: IFilters = {
    year: 0,
    user: 'null',
    timeframe: -1,
    reportType: SubmissionType.UNSET,
};
function getDefaultFilters(filters: Partial<IFilters> = {}) {
    return Object.assign({}, defaultFilters, filters);
}

interface IProps {
    submissions: Submission[] | null;
    uniqueOrganizations: OrganizationSimple[];
    uniqueWindows: WindowSimple[];
    uniqueYears: number[];
}
export default function SubmissionsTable({ submissions, uniqueOrganizations, uniqueWindows, uniqueYears }: IProps) {
    /** true = ascending */
    const [ascending, setAscending] = useState(true);
    const [sortKey, setSortKey] = useState<SortKeys>();
    const [filters, setFilters] = useState(getDefaultFilters);
    const setFilter =
        (filterKey: keyof IFilters): ChangeEventHandler<HTMLSelectElement> =>
        (event) => {
            // * copy value outside of setFilters, lest we use event.persist()
            const filterValue = event.currentTarget.value;
            setFilters((_) => ({
                ..._,
                [filterKey]: filterValue,
            }));
        };

    const sortColumn = (key: SortKeys) => {
        setAscending(sortKey === key ? !ascending : true);
        setSortKey(key);
    };

    const filteredData = useMemo(
        () =>
            submissions?.filter((_) => {
                // ! eqeqeq disabled here because derived filter types are converted
                // ! to string equivalents from coming from setFilter event
                // eslint-disable-next-line eqeqeq
                if (filters.user != 'null' && Number(filters.user) != _.user.organizationId) {
                    return false;
                }
                // eslint-disable-next-line eqeqeq
                if (filters.timeframe != -1 && filters.timeframe != _.window.windowId) {
                    return false;
                }
                // eslint-disable-next-line eqeqeq
                if (filters.reportType != SubmissionType.UNSET && filters.reportType != _.submissionType) {
                    return false;
                }
                // eslint-disable-next-line eqeqeq
                if (filters.year && filters.year != new Date(_.created).getFullYear()) {
                    return false;
                }
                return true;
            }) ?? [],
        [filters, submissions],
    );

    const sortedData = useMemo(() => {
        return filteredData.sort((a, b) => {
            switch (sortKey) {
                case SortKeys.USER:
                    return sort(ascending, a.firstName, b.firstName);
                case SortKeys.ORGANIZATION:
                    return sort(ascending, a.user.organizationSimple?.name, b.user.organizationSimple?.name);
                // case SortKeys.FILENAME:
                //   return sort(ascending, a.submissionFiles?.length, b.submissionFiles?.length)
                //     || sort(ascending, sortFiles(ascending, a.submissionFiles).join(''), sortFiles(ascending, b.submissionFiles).join(''))
                case SortKeys.CREATED:
                    return sort(ascending, a.created, b.created);
                // case SortKeys.DATA_PERIOD:
                // return sort(ascending, a.window.windowStart, b.window.windowStart)
                case SortKeys.TIMEFRAME:
                    return sort(ascending, a.window.windowStart, b.window.windowStart);
                case SortKeys.REPORT_TYPE:
                    return sort(ascending, a.submissionType, b.submissionType);
                case SortKeys.UPLOAD_SUCCEEDED:
                    return sort(ascending, Number(a.submissionComplete), Number(b.submissionComplete));
            }
            return 0;
        });
    }, [ascending, filteredData, sortKey]);

    return (
        <Container>
            <Row>
                <Col className="mt-2">
                    {submissions === null ? (
                        <Loading />
                    ) : (
                        <div>
                            <h3 className="mb-3">
                                Submissions
                                <TooltipPreset text="Only files that were successfully submitted for hashing will appear on this list">
                                    <FontAwesomeIcon className="ml-1 h6" icon={faQuestionCircle} />
                                </TooltipPreset>
                            </h3>
                            <Row>
                                <Col className="d-flex">
                                    <Form.Group className="mr-2">
                                        <Form.Label className="mr-2">Organizations:</Form.Label>
                                        <select onChange={setFilter('user')}>
                                            <option value="null">All Organizations</option>
                                            {uniqueOrganizations.map((organization, index) => {
                                                return (
                                                    <option key={index} value={organization.organizationId}>
                                                        {organization.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </Form.Group>
                                    <Form.Group className="mr-2">
                                        <Form.Label className="mr-2">Timeframes:</Form.Label>
                                        <select onChange={setFilter('timeframe')}>
                                            <option value={-1}>All Timeframes</option>
                                            {uniqueWindows.map((window, index) => {
                                                return (
                                                    <option key={index} value={window.windowId}>
                                                        {formatDate(new Date(window.windowStart!)) +
                                                            ' - ' +
                                                            formatDate(new Date(window.windowEnd!))}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </Form.Group>
                                    <Form.Group className="ml-2">
                                        <Form.Label className="mr-2">Year:</Form.Label>
                                        <select onChange={setFilter('year')}>
                                            <option value={0}>All Years</option>
                                            {uniqueYears.map((year, i) => {
                                                return (
                                                    <option key={i} value={year}>
                                                        {year}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className="mr-2">Report Types:</Form.Label>
                                        <select onChange={setFilter('reportType')}>
                                            <option value={SubmissionType.UNSET}>All Report Types</option>
                                            <option value={SubmissionType.MPH_REPORT}>MPH Report</option>
                                            <option value={SubmissionType.INFORMED_CONSENT}>Informed Consent</option>
                                        </select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Table striped bordered size="sm" className="text-center">
                                <thead>
                                    <tr>
                                        <th onClick={() => sortColumn(SortKeys.ORGANIZATION)}>
                                            Organization
                                            <SortIcon
                                                ascending={ascending}
                                                sorting={sortKey === SortKeys.ORGANIZATION}
                                            />
                                        </th>
                                        <th>Name</th>
                                        <th>File Name</th>
                                        <th onClick={() => sortColumn(SortKeys.REPORT_TYPE)}>
                                            Report Type
                                            <SortIcon
                                                ascending={ascending}
                                                sorting={sortKey === SortKeys.REPORT_TYPE}
                                            />
                                        </th>
                                        <th onClick={() => sortColumn(SortKeys.CREATED)}>
                                            Date
                                            <SortIcon ascending={ascending} sorting={sortKey === SortKeys.CREATED} />
                                        </th>
                                        <th onClick={() => sortColumn(SortKeys.TIMEFRAME)}>
                                            Timeframe
                                            <SortIcon ascending={ascending} sorting={sortKey === SortKeys.TIMEFRAME} />
                                        </th>
                                        {/* <th>Rows</th> */}
                                        <th onClick={() => sortColumn(SortKeys.UPLOAD_SUCCEEDED)}>
                                            Upload Succeeded
                                            <SortIcon
                                                ascending={ascending}
                                                sorting={sortKey === SortKeys.UPLOAD_SUCCEEDED}
                                            />
                                        </th>
                                        <th>Error Message</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {submissions.length > 0 ? (
                                        sortedData.map((_) =>
                                            _.submissionFiles?.map((__, i) => (
                                                <tr key={i}>
                                                    <td>{_.user.organizationSimple?.name}</td>
                                                    <td>{_.firstName + ' ' + _.lastName}</td>
                                                    <td>{__.originalFileName}</td>
                                                    <td>{submissionLabels[_.submissionType as SubmissionType]}</td>
                                                    <td>{formatDate(new Date(_.created!), true)}</td>
                                                    <td>
                                                        {_.window.name ? <div>{_.window.name}</div> : null}
                                                        <div>
                                                            {formatDate(new Date(_.window.windowStart!)) +
                                                                ' - ' +
                                                                formatDate(new Date(_.window.windowEnd!))}
                                                        </div>
                                                    </td>
                                                    <td>{__.uploadSucceed ? 'Yes' : 'No'}</td>
                                                    <td>{_.errorMessage}</td>
                                                </tr>
                                            )),
                                        )
                                    ) : (
                                        <tr>
                                            <td colSpan={6}>No Submissions</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}
